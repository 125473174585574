// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable prefer-const */
// /* eslint-disable jsx-a11y/anchor-has-content */
// /* eslint-disable no-const-assign */
// import React, { useState } from 'react';
// import { Link } from 'gatsby';
// import Img from 'gatsby-image';
// import MobileIcon from '../images/mobile_icon.png';
// import { useMenuitems } from '../hooks/getMenuItems';
// import { useHeaderInfo } from '../hooks/getHeaderInfo';
// import { useSocialLinks } from '../hooks/getSocialLinks';
// import BannerHead from './headerBanner';

// function stripLink(urlPath) {
//     return urlPath.split('https://werockthespectrumatlantagym.wrtsfranchise.com/').pop();
// }

// function MenuItemLink(data) {
//     if (data.hasChildren) {
//         return (
//             <a href="#">
//                 {data.title}
//                 <span className="caret"></span>
//             </a>
//         );
//     }
//     if (data.type === 'custom') {
//         return (
//             <a href={data.path} rel="noopener noreferrer">
//                 {data.title}
//             </a>
//         );
//     }
//     if (data.type === 'taxonomy') {
//         return (
//             <Link to={`/${stripLink(data.path)}`} target="_blank">
//                 {data.title}
//             </Link>
//         );
//     }
//     if (data.type === 'post_type') {
//         const postTypeUrl = data.url
//             .split('https://werockthespectrumatlantagym.wrtsfranchise.com/')
//             .pop();
//         return <Link to={`/${postTypeUrl}`}>{data.title}</Link>;
//     }
// }

// function HeaderMain() {
//     const [popupActive, showPopup] = useState(false);
//     const [menuActive, showMenu] = useState(false);

//     function togglePopup() {
//         showPopup(!popupActive);
//     }

//     function toggleMenu() {
//         showMenu(!menuActive);
//     }

//     const menuItems = useMenuitems();

//     function fakeHandleMenu(e) {
//         console.log('no children');
//     }

//     function handleMenu(e) {
//         e.target.classList.toggle('is_active');
//     }

//     // content
//     const headerInfo = useHeaderInfo();
//     const socialLinks = useSocialLinks();

//     return (
//         <>
//             <header>
//                 <BannerHead />
//                 <nav
//                     className="navbar navbar-expand-lg text-uppercase fixed-top"
//                     id="mainNav"
//                 >
//                     <div className="container display_block">
//                         <Link className="navbar-brand js-scroll-trigger" to="/">
//                             <Img
//                                 fluid={
//                                     headerInfo.logo_header.localFile
//                                         .childImageSharp.fluid
//                                 }
//                             />
//                         </Link>
//                         <div className="headertop">
//                             {/* <h6>Please check our <a className="headerfblink" href="https://www.facebook.com/werockthespectrumkidsgymatlanta" target="_blank">Facebook</a> for daily hours or call ahead!
//                                 {headerInfo.header_working_hours}
//                                 {` `}
//                                 <span className="hourssep">|</span>
//                                 {` `}
//                                 <span className="yellowtxt">
//                                     CONTACT US:  <span> </span>
//                                     <a
//                                         href={`tel:${headerInfo.header_contact_number}`}
//                                     >
//                                         {headerInfo.header_contact_number}
//                                     </a>
//                                 </span>
//                             </h6> */}
//                             <div className='block_butns'>
//                                 <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="https://www.MyPatronRewards.com/WRTSAtlanta" target='_blank'>Rewards</a>
//                             <div className='new-reg-hc'>
//                                     <div className='wrtsbtn yellowbtn healcode-register new-padding'
//                                     dangerouslySetInnerHTML={{
//                                         __html: `
//                                 <healcode-widget data-version="0.2" data-link-class="loginRegister" data-site-id="96640" data-mb-site-id="916083" data-bw-identity-site="false" data-type="account-link" data-inner-html="Login | Register" />
//                                 `,
//                                     }}
//                                 />
//                             </div>
//                             {/* <Link
//                                 to="/register"
//                                 className="wrtsbtn yellowbtn healcode-register"
//                             >
//                                 {headerInfo.header_register_button_text}
//                             </Link> */}
//                             <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="/summer-camps/">Summer Camps 2024</a>
//                             <div
//                                 className={`popup_wrapper ${popupActive ? 'is_active' : ''
//                                     }`}
//                             />
//                             </div>

//                             <a className="call_icon" href="/open-play/"><img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/06/time-icon.png" alt="time" /></a>
//                             <a className="call_icon" href={`tel:${headerInfo.header_contact_number}`}><img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/06/call-icon.png" alt="call" /></a>
//                             <div className="headersocial soc">
//                                 <a
//                                     className="soc-facebook"
//                                     href={socialLinks.social_facebook_link}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                 />
//                                 <a
//                                     className="soc-instagram"
//                                     href={socialLinks.social_instagram_link}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                 />
//                                 {/* <a
//                                     className="soc-twitter"
//                                     href={socialLinks.social_twitter_link}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                 /> */}
//                                 <div className="cart-div">
//                                     <div
//                                         dangerouslySetInnerHTML={{
//                                             __html: socialLinks.cart_link,
//                                         }}
//                                     />
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="headerrgt">
//                             <button
//                                 className="navbar-toggler"
//                                 type="button"
//                                 onClick={toggleMenu}
//                             >
//                                 <img src={MobileIcon} alt="mobile icon" />
//                                 Menu
//                             </button>
//                         </div>
//                     </div>
//                     <div
//                         className={`navitems ${menuActive ? 'display_block' : ''
//                             }`}
//                     >
//                         <ul>
//                             {menuItems.map(menuItem => (
//                                 <li
//                                     className={`${menuItem.wordpress_children
//                                             ? 'nav-dropdown'
//                                             : ''
//                                         }`}
//                                     key={menuItem.wordpress_id}
//                                     id={menuItem.wordpress_id}
//                                     onClick={
//                                         menuItem.wordpress_children
//                                             ? handleMenu
//                                             : fakeHandleMenu
//                                     }
//                                 >
//                                     <MenuItemLink
//                                         path={menuItem.url}
//                                         url={menuItem.url}
//                                         title={menuItem.title}
//                                         type={menuItem.type}
//                                         hasChildren={
//                                             menuItem.wordpress_children
//                                         }
//                                     />

//                                     {menuItem.wordpress_children ? (
//                                         <ul className='children-menu'>
//                                             {menuItem.wordpress_children.map(
//                                                 childrenItem => (
//                                                     <li
//                                                         key={
//                                                             childrenItem.wordpress_id
//                                                         }
//                                                     >
//                                                         <MenuItemLink
//                                                             path={
//                                                                 childrenItem.url
//                                                             }
//                                                             title={
//                                                                 childrenItem.title
//                                                             }
//                                                             type={
//                                                                 childrenItem.type
//                                                             }
//                                                             url={
//                                                                 childrenItem.url
//                                                             }
//                                                             hasChildren={false}
//                                                         />
//                                                     </li>
//                                                 )
//                                             )}
//                                         </ul>
//                                     ) : (
//                                         ''
//                                     )}
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </nav>
//             </header>
//             <div className="headerhigh"></div>
//             {/* <div className="all-popup">
//                 <div className='boxed-popup'>

//                     <span className="close_all_popup" onclick="$('.all-popup').hide()">×</span>
//                     <h2 className='hot-deal_h2'>Join us for our <br />Thanksgiving Sale!</h2>
//                     <p className='hot-deal_p'>See our "Hot Deals" page for special discounts, exciting activities, and a dash of holiday magic!</p>
//                     <p className='hot-deal_bottom_p'><span>Sale Period:</span> MIDNIGHT NOV 23RD - MIDNIGHT NOV 27TH</p>
//                     <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="/hot-deals/">SHOP HOT DEALS</a>

//                 </div>

//             </div> */}
//             <div className="all-popup">
//                 <div className='boxed-popup img'>
//                     <span className="close_all_popup" onclick="$('.all-popup').hide()">×</span>
//                     <a className='' href="/summer-camps/">
//                         <img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/01/Atlanta_Summer_Camp_Popup_Raleway_Light.webp" alt="flyer" />
//                         <p className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header'>SIGN UP</p>
//                     </a>
//                     {/* <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="/summer-camps/">SIGN UP</a> */}
//                 </div>

//             </div>


//             <div
//                 dangerouslySetInnerHTML={{
//                     __html: `<div class="elfsight-app-b6eea101-d6ed-43dc-bd12-1725d9757797" data-elfsight-app-lazy></div>`,
//                 }}
//             />
            

//         </>
//     );
// }

// export default HeaderMain;
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-const-assign */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import MobileIcon from '../images/mobile_icon.png';
import { useMenuitems } from '../hooks/getMenuItems';
import { useHeaderInfo } from '../hooks/getHeaderInfo';
import { useSocialLinks } from '../hooks/getSocialLinks';
import BannerHead from './headerBanner';

function stripLink(urlPath) {
    return urlPath.split('https://werockthespectrumatlantagym.wrtsfranchise.com/').pop();
}

function MenuItemLink(data) {

    if (data.type === 'custom') {
        return (
            <a href={data.path} rel="noopener noreferrer" id={`menu-${data.id}`}>
                {data.title}
            </a>
        );
    }
    if (data.type === 'taxonomy') {
        return (
            <Link to={`/${stripLink(data.path)}`} target="_blank" id={`menu-${data.id}`}>
                {data.title}
            </Link>
        );
    }
    if (data.type === 'post_type') {
        const postTypeUrl = data.url
            .split('https://werockthespectrumatlantagym.wrtsfranchise.com/')
            .pop();
        return <Link to={`/${postTypeUrl}`} id={`menu-${data.id}`}>{data.title}</Link>;
    }
}

function HeaderMain() {
    const [popupActive, showPopup] = useState(false);
    const [menuActive, showMenu] = useState(false);

    function togglePopup() {
        showPopup(!popupActive);
    }

    function toggleMenu() {
        showMenu(!menuActive);
    }

    const menuItems = useMenuitems();

    function fakeHandleMenu(e) {
        console.log('no children');
    }

    function handleMenu(e) {
        e.target.classList.toggle('is_active');
    }

    // content
    const headerInfo = useHeaderInfo();
    const socialLinks = useSocialLinks();

    return (
        <>
            <header>
                <BannerHead />
                <nav
                    className="navbar navbar-expand-lg text-uppercase fixed-top"
                    id="mainNav"
                >
                    <div className="container display_block">
                        <Link className="navbar-brand js-scroll-trigger" to="/">
                            <Img
                                fluid={
                                    headerInfo.logo_header.localFile
                                        .childImageSharp.fluid
                                }
                            />
                        </Link>
                        <script src="https://static.elfsight.com/platform/platform.js" async></script>
                        <div className="headertop">
                            <div className='block_butns'>
                                <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="https://www.MyPatronRewards.com/WRTSAtlanta" target='_blank'>Rewards</a>
                                <div className='new-reg-hc'>
                                    <div className='wrtsbtn yellowbtn healcode-register new-padding'
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                        <healcode-widget data-version="0.2" data-link-class="loginRegister" data-site-id="96640" data-mb-site-id="916083" data-bw-identity-site="false" data-type="account-link" data-inner-html="Login | Register" />
                                        `,
                                        }}
                                    />
                                </div>
                                <div 
                                    className="elfsight-app-e0adfdb3-2672-41b3-921b-7170226013f0" 
                                    data-elfsight-app-lazy
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        marginRight: '8px',
                                    }}
                                ></div>
                           {/*     <a className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header' href="/hot-deals/">Hot Deals</a>  */}
                                <div
                                    className={`popup_wrapper ${popupActive ? 'is_active' : ''}`}
                                />
                            </div>

                            <a className="call_icon" href="/open-play/"><img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/06/time-icon.png" alt="time" /></a>
                            <a className="call_icon" href={`tel:${headerInfo.header_contact_number}`}><img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/06/call-icon.png" alt="call" /></a>
                            <div className="headersocial soc">
                                <a
                                    className="soc-facebook"
                                    href={socialLinks.social_facebook_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                <a
                                    className="soc-instagram"
                                    href={socialLinks.social_instagram_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                                <div className="cart-div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: socialLinks.cart_link,
                                        }}
                                    />
                                </div>
                            </div>
                            {/* script was here*/}
                            <div
                                className="elfsight-app-d79485c0-7535-45ab-98ad-77f97eb22687"
                                data-elfsight-app-lazy
                                style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '8px', }}
                            ></div>
                            <div
                                className="elfsight-app-23621dd8-182d-4426-988d-a82e013a7f5e"
                                data-elfsight-app-lazy
                                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                            ></div>
                        </div>
                        <div className="headerrgt">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleMenu}
                            >
                                <img src={MobileIcon} alt="mobile icon" />
                                Menu
                            </button>
                        </div>
                    </div>
                    <div
                        className={`navitems ${menuActive ? 'display_block' : ''}`}
                    >
                        <ul>
                            {menuItems.map(menuItem => (
                                <li
                                    className={`${menuItem.wordpress_children ? 'nav-dropdown' : ''}`}
                                    key={menuItem.wordpress_id}
                                    id={`menu-li-${menuItem.wordpress_id}`}
                                    onClick={
                                        menuItem.wordpress_children
                                            ? handleMenu
                                            : fakeHandleMenu
                                    }
                                >
                                    <MenuItemLink
                                        id={menuItem.wordpress_id}
                                        path={menuItem.url}
                                        url={menuItem.url}
                                        title={menuItem.title}
                                        type={menuItem.type}
                                        hasChildren={menuItem.wordpress_children}
                                    />

                                    {menuItem.wordpress_children ? (
                                        <ul className='children-menu'>
                                            {menuItem.wordpress_children.map(childrenItem => (
                                                <li
                                                    key={childrenItem.wordpress_id}
                                                    id={`menu-li-${childrenItem.wordpress_id}`}
                                                >
                                                    <MenuItemLink
                                                        id={childrenItem.wordpress_id}
                                                        path={childrenItem.url}
                                                        title={childrenItem.title}
                                                        type={childrenItem.type}
                                                        url={childrenItem.url}
                                                        hasChildren={false}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
            </header>
            <div className="headerhigh"></div>
            <div className="all-popup">
                <div className='boxed-popup img'>
                    <span className="close_all_popup" onClick={() => document.querySelector('.all-popup').style.display = 'none'}>×</span>
                    <a href="/summer-camps/">
                        <img src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/01/Atlanta_Summer_Camp_Popup_Raleway_Light.webp" alt="flyer" />
                        <p className='hot-deal_btn wrtsbtn yellowbtn healcode-register hot_deals_header'>SIGN UP</p>
                    </a>
                </div>
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<div class="elfsight-app-b6eea101-d6ed-43dc-bd12-1725d9757797" data-elfsight-app-lazy></div>`,
                }}
            />
        </>
    );
}

export default HeaderMain;